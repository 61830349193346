import React, { createContext, useContext, ReactNode } from 'react';

interface PhoneContextProps {
    phoneNumber: string;
    formattedPhoneNumber: string;
}

const PhoneContext = createContext<PhoneContextProps | undefined>(undefined);

interface PhoneContextProviderProps {
    phoneNumber: string;
    formattedPhoneNumber: string;
    children: ReactNode;
}

export const PhoneContextProvider: React.FC<PhoneContextProviderProps> = ({
    phoneNumber,
    formattedPhoneNumber,
    children,
}) => (
    <PhoneContext.Provider value={{ phoneNumber, formattedPhoneNumber }}>
      {children}
    </PhoneContext.Provider>
);

export const usePhoneContext = (): PhoneContextProps => {
const context = useContext(PhoneContext);
if (!context) {
    throw new Error('usePhoneContext must be used within a PhoneContextProvider');
}
return context;
};