import React from 'react';
import { PhoneContextProvider } from './context/PhoneContext';
import { getPhoneNumber, formatPhoneNumber } from './util/phoneNumberHelper';

interface WrapPageElementProps {
    element: React.ReactNode;
    props: {
      location: Location;
    };
}

export const wrapPageElement: React.FC<WrapPageElementProps> = ({ element, props }) => {
    const phoneNumber = getPhoneNumber(props.location);
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    
  return (
    <PhoneContextProvider phoneNumber={phoneNumber} formattedPhoneNumber={formattedPhoneNumber}>
      {element}
    </PhoneContextProvider>
  );
};