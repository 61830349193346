export const getPhoneNumber = (location: Location):string => {
    // get source
    const dBing = '18778410886';
    const dGoogle = '18779170027';
    const dMeta = '18779390439';
    const dOrganic = '18775471616';
    const dEasyhealth = '18778090291';
    const dDigitalmediagroup = '18777180081';
    const dMadrivo = '18776680871';
    const dExcelimpact = '18776291988';
    const dAwl = '18776270181';
    const dMaven = '18775922938';
    const dOddbytes = '18775110054';
    const dGenafl = '18777180081';
    const params = new URLSearchParams(location.search);
    let phoneNumber = '';

    // check for vendor param
    if (params.get("vendor")) {
        switch (params.get("vendor")?.toLowerCase()) {
        case 'bing':
            phoneNumber = dBing;
            break;
        case 'google':
            phoneNumber = dGoogle;
            break;
        case 'meta':
            phoneNumber = dMeta;
            break;
        case 'eh':
            phoneNumber = dEasyhealth;
            break;
        case 'dmg':
            phoneNumber = dDigitalmediagroup;
            break;
        case 'mdvo':
            phoneNumber = dMadrivo;
            break;
        case 'ei':
            phoneNumber = dExcelimpact;
            break;
        case 'awl':
            phoneNumber = dAwl;
            break;
        case 'mvn':
            phoneNumber = dMaven;
            break;
        case 'oddbytes':
            phoneNumber = dOddbytes;
            break;
        case 'genafl':
            phoneNumber = dGenafl;
            break;
        default:
            phoneNumber = dOrganic;
        }

    // check for gclid, msclkid, fbclid
    } else if (params.get("gclid")) {
        phoneNumber = dGoogle;
    } else if (params.get("msclkid")) {
        phoneNumber = dBing;
    } else if (params.get("fbclid")) {
        phoneNumber = dMeta;
    } else {
        phoneNumber = dOrganic;
    }

    return phoneNumber;
}

export const formatPhoneNumber = (phoneNumberString: string): string => {

    const formattedNumber = phoneNumberString.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
    return formattedNumber;
}