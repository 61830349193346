exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/AboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/FAQ.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-form-tsx": () => import("./../../../src/pages/form.tsx" /* webpackChunkName: "component---src-pages-form-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wp-landing-page-slug-tsx": () => import("./../../../src/pages/{wpLandingPage.slug}.tsx" /* webpackChunkName: "component---src-pages-wp-landing-page-slug-tsx" */),
  "component---src-pages-wp-page-slug-tsx": () => import("./../../../src/pages/{wpPage.slug}.tsx" /* webpackChunkName: "component---src-pages-wp-page-slug-tsx" */)
}

